<template>
  <div class="pgoffice-page">
    <div id="pageOfficeContent" v-html="html_code" class="office-main"></div>
  </div>
</template>

<script>
import { ViewExcel } from "@/api/pageOffice";

export default {
  name: "pageOffice_word",
  data: function () {
    return {
      html_code: "",
      params: "",
      WordName: "",
    };
  },
  methods: {
    Save: function () {
      document.getElementById("PageOfficeCtrl1").WebSave();
      document.getElementById("PageOfficeCtrl1").WebSaveAsPDF();
      document.getElementById("PageOfficeCtrl1").CustomSaveResult;
      window.external.close();
    },
    Close: function () {
      window.external.close();
    },
    getParam: function () {
      this.params = window.external.UserParams;
      if (this.params) {
        this.params = JSON.parse(this.params);
      }
    },
    init: function () {
      this.getParam();
      if (!this.params || this.params == "" || this.params == undefined) {
        alert("未获取到参数,打开失败");
        return;
      }
      ViewExcel(this.params).then((res) => {
        this.html_code = res;
      });
    },
  },
  created: function () {
    window.BeforeDocumentSaved = function (e) {
      console.log(e);
    };
    window.Save = this.Save;
    window.Close = this.Close;
    this.init();
  },
};
</script>
